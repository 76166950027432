import { Container, Row, Col, Image ,Button,Spinner} from 'react-bootstrap';

export const PollContent = (props) => {

  //   const [numPages, setNumPages] = useState(null);
  // const [loading, setLoading] = useState(true);
  // const [pageNumber, setPageNumber] = useState(1);

  // function onLoadSuccess({ numPages }) {
  //   setNumPages(numPages);
  //   setLoading(false);
  // }

  // function onLoadError() {
  //   setLoading(false);
  // }

  //   const paragraphStyle = {
  //       marginBottom: '2.5rem' // Adjust the value as needed
  //     };

 

  // function onLoadSuccess({ numPages }) {
  //   setNumPages(numPages);
  //   setLoading(false);
  // }

  // function onLoadError() {
  //   setLoading(false);
  // }

  // const handlePrevious = () => {
  //   setPageNumber(prevPageNumber => Math.max(prevPageNumber - 1, 1));
  // };

  // const handleNext = () => {
  //   setPageNumber(prevPageNumber => Math.min(prevPageNumber + 1, numPages));
  // };
  return (
    <>
    <Container id="Reports" className="mt-5">
      <Row className=" ">
    
      <Col>
      <div className="card">
          <Image src={`${process.env.PUBLIC_URL}/1234.jpg`} fluid />    

          <a href="/pdff.pdf" className="btn" download>

                <i className="fas fa-file-pdf  icon"></i> 

          </a>
          <span className="span btn">Download Report</span>
          </div>
        </Col>

        <Col md={6} className="content-column paragraphStyle">
          <h4>The Threat of Extremist Groups Misusing Social Media Platforms and Applications</h4>
          <div class="about-text">
                    <p className="">
                    As part of its opinion poll series, TRENDS Research & Advisory conducted a poll – The Threat of Extremist Groups Misusing Social Media Platforms and Applications – from May 16 to June 3, 2021. The poll focused on exploring the Arab and international public opinion trends related to the misuse, its implications on the security and stability of societies, and how to deal with it decisively. It tried to measure the use of social media platforms and applications in daily life, identifying the most used applications and determining the extent to which individuals follow issues of public concern on social media platforms and applications. It also tried to measure how the youth are influenced by the ideas shared on social media platforms. The poll included questions related to the extent to which religious extremist groups misuse social media platforms and apps to disseminate their ideologies. It also included several questions to identify the threat posed by extremist groups misusing social media platforms and applications. The questions were framed from the Arab and international public opinion perspectives. The objective was to understand the extent of these dangers and to determine how the international community can tackle it and the best measures to combat digital extremism. Respondents comprised a random sample of 415 people of both genders, with varied nationalities, age groups, and educational levels.                    </p>
                    
                </div>
        </Col>
      </Row>
    </Container>

    {/* <Container className="mt-5 ">
      <div className="d-flex justify-content-center mb-3">
        {loading && <Spinner animation="border" />}
      </div>
      <div className="pdf-container ">
      <Image src={`${process.env.PUBLIC_URL}/1234.jpg`} fluid />    

        <Document
          file="/pdff.pdf"
          onLoadSuccess={onLoadSuccess}
          onLoadError={onLoadError}
          loading={<Spinner animation="border" />}
        >
          {Array.from(new Array(numPages), (el, index) => (
            <Page key={`page_${index + 1}`} pageNumber={index + 1} scale={0.75} />
          ))}
        </Document>
      </div>
    </Container> */}
</>


  );
};
