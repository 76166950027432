import React, { useState, useEffect } from "react";
import { Header } from "./components/header";

import { Services } from "./components/services";
// import { Document, Page, pdfjs } from 'react-pdf';

import JsonData from "./data/data.json";
import SmoothScroll from "smooth-scroll";
import Quill from "./components/quill";
import "./App.css";
import { BrowserRouter, Routes, Route ,Switch} from "react-router-dom";
import { Footer } from "./components/footre";
import { PollContent } from "./components/PollContent";
import { Allcards } from "./components/Allcards";
import { Navigation } from "./components/navigation";
// pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

export const scroll = new SmoothScroll('a[href*="#"]', {
  speed: 1000,
  speedAsDuration: true,
});

const App = () => {
  const [landingPageData, setLandingPageData] = useState({});
  useEffect(() => {
    setLandingPageData(JsonData);
  }, []);

  return (
    <div>     

      {/* <Navigation /> */}
      {/* <Header data={landingPageData.Header} /> */}
      {/* <Features data={landingPageData.Features} /> */}
      {/* <Services data={landingPageData.Services} /> */}
      <Navigation/>
      <Routes>
        <Route path="/" element={<Header/>}/>
      <Route path="/services" element={<Services  />} />
      <Route path="/Allcards/Reports" element={<PollContent  />} />
      <Route path="/Allcards" element={<Allcards  />} />

      </Routes>   
         {/* <About data={landingPageData.About} /> */}
<Footer/>
      {/* <Gallery data={landingPageData.Gallery} /> */}
      {/* <Testimonials data={landingPageData.Testimonials} /> */}
      {/* <Team data={landingPageData.Team} />
      <Contact data={landingPageData.Contact} /> */}
      {/* <Quill data={landingPageData.Quill} /> */}

    </div>
  );
};

export default App;

  // "homepage": "https://najmamoh.github.io/research",
