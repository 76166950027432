import React from "react";
  import  { useState, useEffect } from 'react';
  import 'owl.carousel/dist/assets/owl.carousel.css';
  import 'owl.carousel/dist/assets/owl.theme.default.css';
  import '@fortawesome/fontawesome-free/css/all.min.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { About } from "./about";
import { Team } from "./Team";
import { Contact } from "./contact";

  export const Header = (props) => {
  

  const [sticky, setSticky] = useState(false);
  const [showBackToTop, setShowBackToTop] = useState(false);

  const handleScroll = () => {
    const scrollTop = window.scrollY;
    setSticky(scrollTop > 0);
    setShowBackToTop(scrollTop > 200);
  };

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  return (
    <div>
     <div className="top-bar ">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-8">
                        <div className="top-bar-left">
                            {/* <!-- <div className="text">
                                <i className="far fa-clock"></i>
                                <h2>8:00 - 9:00</h2>
                                <p>Mon - Fri</p>
                            </div> --> */}
                            {/* <!-- <div className="text">
                                <i className="fa fa-phone-alt"></i>
                                <h2>+123 456 7890</h2>
                                <p>For Appointment</p>
                            </div> --> */}
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="top-bar-right">
                            <div className="social">
                                <a href=""><i className="fab fa-twitter"></i></a>
                                <a href=""><i className="fab fa-facebook-f"></i></a>
                                <a href=""><i className="fab fa-linkedin-in"></i></a>
                                <a href=""><i className="fab fa-instagram"></i></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>      
        {/* <div className={`navbar ${sticky ? 'nav-sticky' : ''}`}> */}
        {/* <Navigation/> */}


{/* </div> */}

    <div className="carousel ">
    <div className="container-fluid ">
        <div className="">
         
            <div className="carousel-item">
                <div className="carousel-img">
                    <img src={`${process.env.PUBLIC_URL}/img.jpeg`} alt="Image"/>
                </div>
                <div className="carousel-text">
                    <h1>HORASA                      <h6>Horn of Africa research and strategic advisory</h6>
 </h1>
                    <p>
                    Your gateway to the Horn of Africa, a consultancy platform dedicated to showcasing innovative ideas that shape the future of the region.                    </p>
                    <div className="carousel-btn">
                        <a className="btn" href=""><i className="fa fa-link"></i>CONTACT</a>
                        <a className="btn btn-play" data-toggle="modal" data-src="https://www.youtube.com/embed/DWRcNpR6Kdc" data-target="#videoModal"><i className="fa fa-play"></i>Watch Video</a>
                    </div>
                </div>
            </div>
            {/* <div className="carousel-item">
                <div className="carousel-img">
                    <img src="img/img.jpeg" alt="Image"/>
                </div>
                <div className="carousel-text">
                    <h1>Horn of Africa Research </h1>
                    <p>
                        Sed ultrices, est eget feugiat accumsan, dui nibh egestas tortor, ut rhoncus nibh ligula euismod quam. Proin pellentesque odio
                    </p>
                    <div className="carousel-btn">
                        <a className="btn" href=""><i className="fa fa-link"></i>CONTACT</a>
                        <a className="btn btn-play" data-toggle="modal" data-src="https://www.youtube.com/embed/DWRcNpR6Kdc" data-target="#videoModal"><i className="fa fa-play"></i>Watch Video</a>
                    </div>
                </div>
            </div> */}
        </div>
    </div>
</div>
<About  />
<Team/>
<Contact/>
</div>

  );
};
