import React from 'react';

import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
// import 'bootstrap/dist/css/bootstrap.min.css';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import { BrowserRouter,Router ,Route ,HashRouter} from 'react-router-dom';
import './pdfConfig'; // Import the PDF.js configuration

// Set the workerSrc property

// const root = ReactDOM.createRoot(document.getElementById('root'));
ReactDOM.render(
  <HashRouter>
  <React.StrictMode>
    <App />
    </React.StrictMode>
    </HashRouter>,
document.getElementById('root')

);
// root.render(  
// <HashRouter>

//   <React.StrictMode>
//     <App />
//   </React.StrictMode>  
//     </HashRouter>,

// );

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
