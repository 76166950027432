import React from 'react';
import OneCards from './Cards';
import { Card} from 'react-bootstrap';
import Report from './PollContent'
import { useNavigate } from 'react-router-dom';

const cardsData = [
  {
    image: './1234.jpg',
    title: 'The Threat of Extremist Groups Misusing Digital and Social Media Platforms',
    date: '01 Jun 2021'
  },
  {
    image: './1234.jpg',
    title: 'Global Economic Recovery in the Post-Covid-19 Era',
    date: '01 May 2021'
  },
  {
    image: './1234.jpg',
    title: 'Joe Biden’s First Month in Office: Measuring Public Opinion on Policies Toward the MENA Region',
    date: '01 Mar 2021'
  },
  {
    image: './1234.jpg',
    title: 'International Geostrategic & Security Landscape in 2021',
    date: '01 Jan 2021'
  }
];

export const Allcards = () => {
  const navigate = useNavigate();
  function GoReport(params) {
    navigate("Reports")
}
  return (
    <>
     <div className="header-container">
      <h1 className="header-title">Barometer Analytical Reports</h1>
      <hr className="header-line" />
    </div>
    <div className="d-flex flex-wrap ">
      {cardsData.map((card, index) => (
        <Card style={{ width: '26rem' }} className='mx-auto'onClick={GoReport}>
      <Card.Img variant="top" src={card.image} />
      <Card.Body>
        <Card.Title>{card.title}</Card.Title>
        <Card.Text>
          {card.date}
        </Card.Text>
      </Card.Body>
    </Card>      ))}
    </div></>
  );
};

