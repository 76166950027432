import React from "react";
import  { useState, useEffect } from 'react';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Navbar, Nav, NavDropdown, Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';

export const Navigation = (props) => {
  const [sticky, setSticky] = useState(false);

  const handleScroll = () => {
    if (window.scrollY > 0) {
      setSticky(true);
    } else {
      setSticky(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  return (
    <Navbar expand="lg" className= {`py-3  custom-navbar ${sticky ? 'nav-sticky' : ''}`} >
          <Navbar.Toggle aria-controls="basic-navbar-nav" className="me-auto mx-3 Navbar.Toggle"/>
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="  gap-lg-5 justify-content-end  w-100    container-fluid">
              <Nav.Link className="" href="/">Home</Nav.Link>
              {/* <Nav.Link className=""href="#About">About</Nav.Link> */}
              <Nav.Link  href="#About" className="">About</Nav.Link>

              <Nav.Link as={Link} to="services" className="">Services</Nav.Link>
              <Nav.Link className=""href="#Strategic">Strategic Objectives</Nav.Link>
              <Nav.Link as={Link} to="Allcards" className="">Reports</Nav.Link>

              {/* <NavDropdown className=""  title="Strategic Objectives" id="basic-nav-dropdown">
              <NavDropdown.Item href="#action/3.2" className="">Topics</NavDropdown.Item>
              <NavDropdown.Divider />

                <NavDropdown.Item href="#action/3.2">Security issues</NavDropdown.Item>
                <NavDropdown.Item href="#action/3.3">Trade flows</NavDropdown.Item>
                <NavDropdown.Item href="#action/3.4">Humanitarian and Climate Change HOA</NavDropdown.Item>
              

                <NavDropdown.Divider />
              </NavDropdown> */}
              <NavDropdown className=""  title="Reasources" id="basic-nav-dropdown">
                <NavDropdown.Item href="#action/3.3">Annual Reports</NavDropdown.Item>
                <NavDropdown.Item href="#action/3.4">presentations</NavDropdown.Item>
                <NavDropdown.Item href="#action/3.4">Events</NavDropdown.Item>
                <NavDropdown.Item href="#action/3.4">Publications</NavDropdown.Item>
                <NavDropdown.Item href="#action/3.4">Medi Converage</NavDropdown.Item>
                <NavDropdown.Item href="#action/3.4">Press Releases</NavDropdown.Item>
                <NavDropdown.Item href="#action/3.4">Speeches</NavDropdown.Item>
                <NavDropdown.Item href="#action/3.4">Newsletters</NavDropdown.Item>
                <NavDropdown.Item href="#action/3.4">Gallery</NavDropdown.Item>


              </NavDropdown>
                {/* <Nav.Link className=""href="#link">Review</Nav.Link> */}
                <Nav.Link className=""href="#contact">CONTACT</Nav.Link>
 </Nav>
          </Navbar.Collapse>
    </Navbar> 
  );
};
